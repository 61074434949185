import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import { formatMoney } from '../../util/currency';
import { timestampToDate } from '../../util/dates';
import { createSlug } from '../../util/urlHelpers';

import { Page, LayoutSingleColumn } from '../../components';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';

import css from './ListingPage.module.css';
import CustomFooter from '../../components/CustomFooter/CustomFooter';

/**
 * This file contains shared functions from each ListingPage variants.
 */

const { UUID } = sdkTypes;

/**
 * Helper to get formattedPrice and priceTitle for SectionHeading component.
 * @param {Money} price listing's price
 * @param {String} marketplaceCurrency currency of the price (e.g. 'USD')
 * @param {Object} intl React Intl instance
 * @returns Object literal containing formattedPrice and priceTitle
 */
export const priceData = (price, marketplaceCurrency, intl) => {
  if (price && price.currency === marketplaceCurrency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};
export function findMinMaxPriceRange(moneyArray) {
  if (moneyArray.length > 0) {
    let minAmount = moneyArray[0].amount;
    let maxAmount = moneyArray[0].amount;

    for (let i = 1; i < moneyArray.length; i++) {
      if (moneyArray[i].amount < minAmount) {
        minAmount = moneyArray[i].amount;
      }
      if (moneyArray[i].amount > maxAmount) {
        maxAmount = moneyArray[i].amount;
      }
    }

    return {
      min: minAmount,
      max: maxAmount,
    };
  } else {
    let amount = moneyArray[0].amount;
    return amount;
  }
}
export function formatPriceRange(min, max) {
  const formattedMin = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  })
    .format(min / 100)
    .split('.')[0]; // Assuming the amount is in pence, hence dividing by 100

  const formattedMax = new Intl.NumberFormat('en-GB', {
    // style: 'currency',
    currency: 'GBP',
  })
    .format(max / 100)
    .split('.')[0]; // Assuming the amount is in pence, hence dividing by 100

  return `${formattedMin} - ${formattedMax} ph`;
}
export function formatPriceRangeSingle(amount) {
  const formattedAmount = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  })
    .format(amount / 100)
    .split('.')[0]; // Assuming the amount is in pence, hence dividing by 100

  return `${formattedAmount} ph`;
}
/**
 * Get category's label.
 *
 * @param {Array} categories array of category objects (key & label)
 * @param {String} value selected category value
 * @returns label for the selected value
 */
export const categoryLabel = (categories, value) => {
  const cat = categories.find(c => c.key === value);
  return cat ? cat.label : value;
};

/**
 * Filter listing images with correct custom image variant name.
 * Used for facebook, twitter and page schema images.
 *
 * @param {Listing} listing
 * @param {String} variantName
 * @returns correct image variant specified by variantName parameter.
 */
export const listingImages = (listing, variantName) =>
  (listing.images || [])
    .map(image => {
      const variants = image.attributes.variants;
      const variant = variants ? variants[variantName] : null;

      // deprecated
      // for backwards combatility only
      const sizes = image.attributes.sizes;
      const size = sizes ? sizes.find(i => i.name === variantName) : null;

      return variant || size;
    })
    .filter(variant => variant != null);

/**
 * Callback for the "contact" button on ListingPage to open inquiry modal.
 *
 * @param {Object} parameters all the info needed to open inquiry modal.
 */
export const handleContactUser = parameters => () => {
  const {
    history,
    params,
    currentUser,
    callSetInitialValues,
    location,
    routes,
    setInitialValues,
    setInquiryModalOpen,
  } = parameters;

  if (!currentUser) {
    const state = { from: `${location.pathname}${location.search}${location.hash}` };

    // We need to log in before showing the modal, but first we need to ensure
    // that modal does open when user is redirected back to this listingpage
    callSetInitialValues(setInitialValues, { inquiryModalOpenForListingId: params.id });

    // signup and return back to listingPage.
    history.push(createResourceLocatorString('SignupPage', routes, {}, {}), state);
  } else {
    setInquiryModalOpen(true);
  }
};

/**
 * Callback for the inquiry modal to submit aka create inquiry transaction on ListingPage.
 * Note: this is for booking and purchase processes. Inquiry process is handled through handleSubmit.
 *
 * @param {Object} parameters all the info needed to create inquiry.
 */
export const handleSubmitInquiry = parameters => values => {
  const { history, params, getListing, onSendInquiry, routes, setInquiryModalOpen } = parameters;
  const listingId = new UUID(params.id);
  const listing = getListing(listingId);
  const { message } = values;

  onSendInquiry(listing, message.trim())
    .then(txId => {
      setInquiryModalOpen(false);

      // Redirect to OrderDetailsPage
      history.push(createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {}));
    })
    .catch(() => {
      // Ignore, error handling in duck file
    });
};

/**
 * Handle order submit from OrderPanel.
 *
 * @param {Object} parameters all the info needed to redirect user to CheckoutPage.
 */
export const handleSubmit = parameters => values => {
  const {
    history,
    params,
    currentUser,
    getListing,
    callSetInitialValues,
    onInitializeCardPaymentData,
    routes,
  } = parameters;
  const listingId = new UUID(params.id);

  const listing = getListing(listingId);

  const {
    bookingDates,
    bookingStartTime,
    bookingEndTime,
    bookingStartDate, // not relevant (omit)
    bookingEndDate, // not relevant (omit)
    quantity: quantityRaw,
    deliveryMethod,
    guests,
    bookingType,
    serviceName,
    serviceId,
    eventName,
    ...otherOrderData
  } = values;
  const bookingMaybe = bookingDates
    ? {
        bookingDates: {
          bookingStart: bookingDates.startDate,
          bookingEnd: bookingDates.endDate,
        },
      }
    : bookingStartTime && bookingEndTime
    ? {
        bookingDates: {
          bookingStart: timestampToDate(bookingStartTime),
          bookingEnd: timestampToDate(bookingEndTime),
        },
      }
    : {};
  const quantity = Number.parseInt(quantityRaw, 10);
  const quantityMaybe = Number.isInteger(quantity) ? { quantity } : {};
  const deliveryMethodMaybe = deliveryMethod ? { deliveryMethod } : {};
  const serviceNameMaybe = serviceName ? { serviceName } : {};
  const serviceIdMaybe = serviceId ? { serviceId } : {};
  const eventNameMaybe = eventName ? { eventName } : {};
  const initialValues = {
    listing,
    orderData: {
      guests,
      bookingType,
      ...bookingMaybe,
      ...quantityMaybe,
      ...deliveryMethodMaybe,
      ...otherOrderData,
      ...serviceNameMaybe,
      ...serviceIdMaybe,
      ...eventNameMaybe,
    },
    confirmPaymentError: null,
  };

  const saveToSessionStorage = !currentUser;

  // Customize checkout page state with current listing and selected orderData
  const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

  callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

  // Clear previous Stripe errors from store if there is any
  onInitializeCardPaymentData();

  // Redirect to CheckoutPage
  history.push(
    createResourceLocatorString(
      'CheckoutPage',
      routes,
      { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
      {}
    )
  );
};

/**
 * Create fallback views for the ListingPage: LoadingPage and ErrorPage.
 * The PlainPage is just a helper for them.
 */
const PlainPage = props => {
  const { title, topbar, scrollingDisabled, children } = props;
  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn topbar={topbar} footer={<CustomFooter />}>
        {children}
      </LayoutSingleColumn>
    </Page>
  );
};

export const ErrorPage = props => {
  const { topbar, scrollingDisabled, intl } = props;
  return (
    <PlainPage
      title={intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      })}
      topbar={topbar}
      scrollingDisabled={scrollingDisabled}
    >
      <p className={css.errorText}>
        <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
      </p>
    </PlainPage>
  );
};

export const LoadingPage = props => {
  const { topbar, scrollingDisabled, intl } = props;
  return (
    <PlainPage
      title={intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      })}
      topbar={topbar}
      scrollingDisabled={scrollingDisabled}
    >
      <p className={css.loadingText}>
        <FormattedMessage id="ListingPage.loadingListingMessage" />
      </p>
    </PlainPage>
  );
};

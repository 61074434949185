import { bool, func } from 'prop-types';
import { string } from 'prop-types';
import React, { Children, useEffect, useRef, useState } from 'react';
import { Field } from 'react-final-form';
import { SelectContextProvider } from './selectContext';
import css from './FieldCustomSelect.module.css';
import classNames from 'classnames';

function FieldCustomSelectComponent(props) {
  const { input, children, label, id, closeOnSelect = false, className } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState({});
  const [defaultValue, setDefaultValue] = useState(null);
  const selectRef = useRef(null);

  const childrenCount = children ? Children.count(children) : 0;
  const classes = classNames(css.root, className);

  const { value, onChange, name } = input;
  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const handleOptionSelect = e => {
    const value = e.currentTarget.value;
    if (options.hasOwnProperty(value)) {
      onChange(e);
    }
    if (closeOnSelect) {
      setIsOpen(false);
    }
  };

  const addOption = (value, label) => {
    setOptions(options => ({ ...options, [value]: label }));
  };

  const handleClick = e => {
    e.preventDefault();
    if (childrenCount > 1) {
      setIsOpen(isOpen => !isOpen);
    }
  };
  const handleDivBlur = e => {
    if (selectRef && selectRef.current) {
      if (!selectRef.current.contains(e.relatedTarget)) {
        setIsOpen(false);
      }
    }
  };

  useEffect(() => {
    if (childrenCount > 0) {
      const [firstChild, secndChild] = children;
      if (!firstChild) return;
      const disabled = !!firstChild.props.disabled;
      const hidden = !!firstChild.props.hidden;
      const value = disabled ? secndChild?.props?.value : firstChild.props.value;
      const label = disabled ? secndChild?.props?.children : firstChild.props.children;
      setDefaultValue({ value, label });
      if (!hidden) {
        onChange(value);
      }
    }
  }, [children]);

  return (
    <SelectContextProvider
      value={{
        selectedValue: value || defaultValue?.value,
        onChange: handleOptionSelect,
        addOption,
      }}
    >
      <div className={classes} tabIndex="-1" onBlur={handleDivBlur} ref={selectRef}>
        <div>
          {label ? (
            <label htmlFor={id} className={css.label}>
              {label}
            </label>
          ) : null}
          <button
            type="button"
            onClick={handleClick}
            className={classNames(css.valueContainer, {
              [css.openedContainer]: isOpen,
              [css.hiddentArrow]: childrenCount < 2,
            })}
            id={id}
          >
            {(value && options[value]) || (defaultValue && defaultValue.label)}
          </button>
        </div>
        {isOpen ? <div className={css.optionWrapper}>{children}</div> : null}
      </div>
    </SelectContextProvider>
  );
}

FieldCustomSelectComponent.defaultProps = {
  className: null,
  closeOnSelect: false,
};

FieldCustomSelectComponent.propTypes = {
  renderValue: func,
  className: string,
  label: string,
  id: string,
  closeOnSelect: bool,
};

function FieldCustomSelect(props) {
  return <Field component={FieldCustomSelectComponent} {...props} />;
}
export default FieldCustomSelect;

import { createContext, useContext } from 'react';

const selectContext = createContext({});

export const SelectContextProvider = selectContext.Provider;

export const useSelectContext = () => {
  const context = useContext(selectContext);
  return context;
};

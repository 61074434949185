import { io } from 'socket.io-client';
import { apiBaseUrl } from './api';

export const getSockeRef = (throwIfWindowNotFound = true) => {
  if (typeof window === 'undefined' && throwIfWindowNotFound) {
    throw new Error('This should use in browser.');
  }
  if (typeof window.socket === 'undefined') {
    const url = apiBaseUrl();
    const ioInstance = io(url);
    window.socket = ioInstance;
  }
  return window.socket;
};

import React from 'react';
import classNames from 'classnames';
import css from './CancelPolicy.module.css';
import { string } from 'prop-types';
import { FormattedMessage } from 'react-intl';

function CancelPolicy(props) {
  const { className, titleClassName, bodyClassName, isEvent } = props;
  const classes = classNames(css.main, className);
  return (
    <div className={classNames(classes, css.cancelPolicy)}>
      <span className={titleClassName}>
        <FormattedMessage id="cancelPolicy.title" />
      </span>
      <p className={bodyClassName}>
        {isEvent ? (
          <FormattedMessage id="eventCancelPolicy.desc" />
        ) : (
          <FormattedMessage id="cancelPolicy.desc" />
        )}
      </p>
    </div>
  );
}

CancelPolicy.propsTypes = {
  className: string,
  titleClassName: string,
  bodyClassName: string,
};

export default CancelPolicy;

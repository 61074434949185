import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ReactComponent as InPerson } from '../../assets/inPerson.svg';
import css from './OfflineWorkout.module.css';
import classNames from 'classnames';

function OfflineWorkout(props) {
  const { intl, className } = props;
  const classes = classNames(css.property, className);
  return (
    <div className={classes}>
      <InPerson className={css.mobileIcon} />
      <FormattedMessage id="OfflineWorkout.title" />
      <InPerson className={css.icon} />
    </div>
  );
}

export default injectIntl(OfflineWorkout);

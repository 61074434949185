import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import {
  getAllTransitionsForEveryProcess,
  getPastTransitionsForEveryProcess,
  getToReviewTransitionsForEveryProcess,
  getUpcomingTransitionsForEveryProcess,
} from '../../transactions/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { providerBookingTab } from '../../custom-config';
import { fetchCurrentUserNotifications } from '../../ducks/user.duck';
import { apiBaseUrl } from '../../util/api';
import axios from 'axios';
import { findNextBoundary, getStartOf, monthIdString } from '../../util/dates';
import { transitions } from '../../transactions/transactionProcessBooking';

const getTransitions = type => {
  switch (type) {
    case providerBookingTab.past:
      return getPastTransitionsForEveryProcess();
    case providerBookingTab.upcoming:
      return getUpcomingTransitionsForEveryProcess();
    case providerBookingTab.toReview:
      return getToReviewTransitionsForEveryProcess();
    default:
      return getAllTransitionsForEveryProcess();
  }
};

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';

export const TRANSITION_REQUEST = 'app/InboxPage/MARK_RECEIVED_REQUEST';
export const TRANSITION_SUCCESS = 'app/InboxPage/TRANSITION_SUCCESS';
export const TRANSITION_ERROR = 'app/InboxPage/TRANSITION_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/InboxPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/InboxPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/InboxPage/FETCH_TIME_SLOTS_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  eventSlots: [],
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
  transitionInProgress: false,
  transitionError: null,
};

export default function inboxPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    case TRANSITION_REQUEST:
      return { ...state, transitionInProgress: true, transitionError: null };

    case TRANSITION_SUCCESS:
      return { ...state, transitionInProgress: false };

    case TRANSITION_ERROR:
      return { ...state, transitionInProgress: false, transitionError: payload };
    case FETCH_TIME_SLOTS_REQUEST: {
      const monthlyTimeSlots = {
        ...state?.monthlyTimeSlots,
        // [payload]: {
        //   ...state?.monthlyTimeSlots[payload],
        //   fetchTimeSlotsError: null,
        //   fetchTimeSlotsInProgress: true,
        // },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_SUCCESS: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          timeSlots: payload.timeSlots,
        },
      };
      const eventSlots = [...state.eventSlots, ...(payload.eventSlots || [])];
      return { ...state, monthlyTimeSlots, eventSlots };
    }
    case FETCH_TIME_SLOTS_ERROR: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          fetchTimeSlotsError: payload.error,
        },
      };
      return { ...state, monthlyTimeSlots };
    }

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

const transitionRequest = () => ({ type: TRANSITION_REQUEST });
const transitionSuccess = () => ({
  type: TRANSITION_SUCCESS,
});
const transitionError = e => ({
  type: TRANSITION_ERROR,
  payload: e,
});

export const fetchTimeSlotsRequest = monthId => {
  return {
    type: FETCH_TIME_SLOTS_REQUEST,
    payload: monthId,
  };
};
export const fetchTimeSlotsSuccess = (monthId, timeSlots, eventSlots) => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: { timeSlots, monthId, eventSlots },
});
export const fetchTimeSlotsError = (monthId, error) => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: { monthId, error },
});

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 10;

const fetchTransactions = (params, search) => (dispatch, getState, sdk) => {
  const { tab } = params;

  const onlyFilterValues = {
    orders: 'order',
    sales: 'sale',
  };

  const onlyFilter = onlyFilterValues[tab];
  if (!onlyFilter) {
    return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
  }

  dispatch(fetchOrdersOrSalesRequest());

  const { page = 1, type } = parse(search);

  const apiQueryParams = {
    only: onlyFilter,
    lastTransitions: getTransitions(type),
    include: [
      'listing',
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking',
    ],
    'fields.transaction': [
      'processName',
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'lineItems',
      'protectedData',
    ],
    'fields.listing': ['title', 'description', 'availabilityPlan', 'publicData'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    perPage: INBOX_PAGE_SIZE,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrdersOrSalesSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};

export const transitionInProgress = state => {
  return state.InboxPage.transitionInProgress;
};

const delay = ms => new Promise(resolve => window.setTimeout(resolve, ms));
const refreshTransactionEntity = (params, search, dispatch) => {
  return delay(1000)
    .then(() => dispatch(fetchTransactions(params, search)))
    .catch(e => {
      // refresh failed, but we don't act upon it.
      console.log('error', e);
      return Promise.reject(e);
    });
};

/**
 * @param {string} transactionId
 */
export const createVideoLink = async transactionId => {
  const url = `${apiBaseUrl()}/api/meeting/create-link`;
  const resp = await axios.post(url, { transactionId });
  return resp.data;
};
export const createRefund = async transactionId => {
  const url = `${apiBaseUrl()}/api/stripe-handler/refund-transaction/${transactionId}`;
  const resp = await axios.get(url, { withCredentials: true });
  return resp.data;
};

export const makeTransition = (txId, transitionName, txParams, pathParams, query) => (
  dispatch,
  getState,
  sdk
) => {
  if (transitionInProgress(getState())) {
    return Promise.reject(new Error('Transition already in progress'));
  }
  dispatch(transitionRequest(transitionName));
  let preTransitionPromise = Promise.resolve();
  if (txParams.shouldCreateMettingLink) {
    delete txParams.shouldCreateMettingLink;
    preTransitionPromise = createVideoLink(txId.uuid).then(data => {
      if (!txParams.hasOwnProperty('protectedData')) {
        txParams.protectedData = {};
      }
      Object.assign(txParams.protectedData, data);
      return data;
    });
  }
  const shouldRefund =
    transitionName === transitions.CUSTOMER_CANCEL_BEFORE_1_HOUR ||
    transitionName === transitions.PROVIDER_CANCEL_BEFORE_1_HOUR ||
    transitionName === transitions.CUSTOMER_CANCEL_BEFORE_24_HOUR ||
    transitionName === transitions.PROVIDER_CANCEL_BEFORE_24_HOUR ||
    transitionName === transitions.PROVIDER_CANCEL_EVENT ||
    transitionName === transitions.CUSTOMER_CANCEL ||
    transitionName === transitions.PROVIDER_CANCEL;
  if (shouldRefund) {
    preTransitionPromise = createRefund(txId.uuid).then(data => {
      if (!txParams.hasOwnProperty('protectedData')) {
        txParams.protectedData = {};
      }
      Object.assign(txParams.protectedData, data);
      return data;
    });
  }
  return preTransitionPromise
    .then(() => {
      return sdk.transactions.transition({
        id: txId,
        transition: transitionName,
        params: txParams,
      });
    })
    .then(() => refreshTransactionEntity(pathParams, query, dispatch))
    .then(() => {
      dispatch(transitionSuccess());
      dispatch(fetchCurrentUserNotifications());
      return Promise.resolve('ok');
    })
    .catch(e => {
      dispatch(transitionError(storableError(e)));
      // log.error(e, `${transitionName}-failed`, {
      //   txId,
      //   transition: transitionName,
      // });
      // throw e;
      return Promise.reject('failed');
    });
};

// const timeSlotsRequest = params => (dispatch, getState, sdk) => {
//   return sdk.timeslots.query(params).then(response => {
//     return denormalisedResponseEntities(response);
//   });
// };

// export const fetchTransactionLineItems = ({ orderData, listingId, isOwnListing }) => dispatch => {
//   dispatch(fetchLineItemsRequest());
//   transactionLineItems({ orderData, listingId, isOwnListing })
//     .then(response => {
//       const lineItems = response.data;
//       dispatch(fetchLineItemsSuccess(lineItems));
//     })
//     .catch(e => {
//       dispatch(fetchLineItemsError(storableError(e)));
//       log.error(e, 'fetching-line-items-failed', {
//         listingId: listingId.uuid,
//         orderData,
//       });
//     });
// };

// export const fetchTimeSlots = (listingId, start, end, timeZone) => (dispatch, getState, sdk) => {
//   const monthId = monthIdString(start, timeZone);

//   dispatch(fetchTimeSlotsRequest(monthId));

//   // The maximum pagination page size for timeSlots is 500
//   const extraParams = {
//     perPage: 500,
//     page: 1,
//   };

//   return dispatch(timeSlotsRequest({ listingId, start, end, ...extraParams }))
//     .then(timeSlots => {
//       dispatch(fetchTimeSlotsSuccess(monthId, timeSlots));
//     })
//     .catch(e => {
//       dispatch(fetchTimeSlotsError(monthId, storableError(e)));
//     });
// };

// export const fetchMonthlyTimeSlots = listing => dispatch => {
//   const hasWindow = typeof window !== 'undefined';
//   const attributes = listing?.attributes;
//   // Listing could be ownListing entity too, so we just check if attributes key exists
//   const hasTimeZone =
//     attributes && attributes.availabilityPlan && attributes.availabilityPlan.timezone;

//   // Fetch time-zones on client side only.
//   if (hasWindow && listing?.id && hasTimeZone) {
//     const tz = listing.attributes.availabilityPlan.timezone;
//     const unitType = attributes?.publicData?.unitType;
//     const timeUnit = unitType === 'hour' ? 'minutes' : 'day';
//     const nextBoundary = findNextBoundary(new Date(), timeUnit, tz);

//     const nextMonth = getStartOf(nextBoundary, 'month', tz, 1, 'months');
//     const nextAfterNextMonth = getStartOf(nextMonth, 'month', tz, 1, 'months');

//     return Promise.all([
//       dispatch(fetchTimeSlots(listing.id, nextBoundary, nextMonth, tz)),
//       dispatch(fetchTimeSlots(listing.id, nextMonth, nextAfterNextMonth, tz)),
//     ]);
//   }

//   // By default return an empty array
//   return Promise.all([]);
// };

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  return dispatch(fetchTransactions(params, search));
};

import React, { useState } from 'react';
import css from './CustomFooter.module.css';
import classNames from 'classnames';
import LinkedLogo from '../Logo/LinkedLogo';
import NamedLink from '../NamedLink/NamedLink';
import { FormattedMessage } from 'react-intl';
import PaymentGatewaysLogo from '../../assets/paymentGatewaysLogo.png';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createResourceLocatorString } from '../../util/routes';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import Modal from '../Modal/Modal';
import ContactUsPage from '../../containers/ContactUsPage/ContactUsPage';
import { manageDisableScrolling } from '../../ducks/ui.duck';
import ExternalLink from '../ExternalLink/ExternalLink';
import SubscribeSection from '../SubscribeSection/SubscribeSection';
// import BlackLogoFleggs from '../../assets/FullLogo.svg';
import { ReactComponent as BlackLogoFleggs } from '../../assets/FullLogo.svg';

const siteInstagramPage = 'https://www.instagram.com/fleggs_it/';
const siteTiktokPage = 'https://www.tiktok.com/@fleggsit';

// import socialMediaLinks from '../../../config';
function CustomFooter(props) {
  const { currentUser, history, onManageDisableScrolling } = props;
  const routeConfiguration = useRouteConfiguration();
  const [isOpen, setIsOpen] = useState(false);
  const privacyPolicyLink =
    process.env.REACT_APP_ENV_CHECK === 'development'
      ? 'https://fleggsit-dev-48ecee2eb242.herokuapp.com/privacy-policy'
      : 'https://www.fleggsit.com/privacy-policy';
  const tcLink =
    process.env.REACT_APP_ENV_CHECK === 'development'
      ? 'https://fleggsit-dev-48ecee2eb242.herokuapp.com/terms-of-service'
      : 'https://www.fleggsit.com/terms-of-service';
  return (
    <>
      <div className={css.footer}>
        <div className={css.footerContainer}>
          <div className={classNames(css.topContent)}>
            <div className={css.linkDiv}>
              {/* <LinkedLogo
                rootClassName={css.logoLink}
                logoClassName={css.logoWrapper}
                logoImageClassName={css.logoImage}
                // linkToExternalSite={linkLogoToExternalSite}
                // layout={logoLayout}
                
              /> */}
              <div className={css.logoLink}>
                <NamedLink name="LandingPage">
                  <BlackLogoFleggs />
                </NamedLink>
              </div>

              <div className={css.linkColumn}>
                <NamedLink
                  name="AboutUsPage"
                  className={classNames(css.listingLinkText, css.highlight)}
                >
                  <span className={css.priorityLinkLabel}>
                    <span>About</span>
                  </span>
                </NamedLink>

                {currentUser?.attributes?.profile?.publicData?.isTrainer === false ? null : (
                  <NamedLink
                    name="NewListingPage"
                    className={classNames(css.listingLinkText, css.highlight)}
                  >
                    <span className={css.priorityLinkLabel}>
                      <FormattedMessage id="CustomTopbar.createListing" />
                    </span>
                  </NamedLink>
                )}
                <span
                  onClick={() =>
                    history.push(
                      createResourceLocatorString('ProfileSettingsPage', routeConfiguration, {}, {})
                    )
                  }
                >
                  Account
                </span>
                <span onClick={() => setIsOpen(true)}>Contact</span>
                <ExternalLink key="linkToInstagram" href={siteInstagramPage}>
                  <span>Instagram</span>
                </ExternalLink>
                <ExternalLink key="linkToTiktok" href={siteTiktokPage}>
                  <span>Tiktok</span>
                </ExternalLink>
              </div>
            </div>

            {/* <div className={css.connectDiv}>
              <div>
                <span>Stay Connected</span>
                <label>Email</label>
                <input type="email" />
                <p>
                  By joining, you agree to the Fleggs Terms of Service and to occasionally receive
                  emails from us. Please read our Privacy Policy to learn how we use your personal
                  data.
                </p>
              </div>
              <div>
                <button
                  onClick={() => {
                    setIsSubOpen(true);
                  }}
                >
                  Subscribe
                </button>
              </div>
            </div> */}
            <SubscribeSection onManageDisableScrolling={onManageDisableScrolling} />
          </div>
          <div className={css.bottomContent}>
            <div>
              <img src={PaymentGatewaysLogo} alt="PaymentGatewaysLogo" />
              <span>Fleggs © 2024</span>
            </div>
            <div>
              {/* <span>FAQs</span> */}
              <span>
                <a href={tcLink}>Terms of Service</a>
              </span>
              <span>
                <a href={privacyPolicyLink}>Privacy Policy</a>
              </span>
              <span>
                <a href="">All Rights Reserved</a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <Modal
        // containerClassName={css.carouselModalContainer}
        // scrollLayerClassName={css.carouselModalScrollLayer}
        className={css.ContactUsmodal}
        id="contactUsModal"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        // showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        {isOpen && <ContactUsPage isOpen={isOpen} />}
      </Modal>
    </>
  );
}

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(CustomFooter);

import React from 'react';
import css from './PromoBanner.module.css';

import { ReactComponent as LogoImg } from '../../assets/shortlogo.svg';
import { ReactComponent as RightArrow } from '../../assets/rightSvg.svg';

import { NamedLink } from '../../components';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

function PromoBanner(props) {
  const { isAuthenticated, className } = props;
  return (
    <div className={classNames(css.promoNav,className)}>
      {/* <LogoImg className={css.shortlogoSvg} /> */}
      {/* {!isAuthenticated ? (
        <NamedLink name="SignupPage" className={css.linkTxt}>
          <span className={css.topbarLinkLabel}>
            <FormattedMessage id="PromoBanner.JoinTheFamily" />
          </span>
        </NamedLink>
      ) : (
        <NamedLink name="TrainerLandingPage" className={css.linkTxt}>
          <span className={css.topbarLinkLabel}>
            <FormattedMessage id="PromoBanner.JoinTheFamily" />
          </span>
        </NamedLink>
      )} */}
      <NamedLink name="TrainerLandingPage" className={css.linkTxt}>
        <span className={css.topbarLinkLabel}>
          <FormattedMessage id="PromoBanner.JoinTheFamily" />
        </span>
      </NamedLink>
      <RightArrow className={css.rightSvg} />
    </div>
  );
}

export default PromoBanner;

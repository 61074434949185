import React, { useState } from 'react';
import css from './SubscribeSection.module.css';
import Modal from '../Modal/Modal';
import { Form as FinalForm } from 'react-final-form';
import axios from 'axios';
import { apiBaseUrl } from '../../util/api';
import Form from '../Form/Form';
import FieldTextInput from '../FieldTextInput/FieldTextInput';
import { IconSpinner } from '..';

const SubscribeSection = props => {
  const { onManageDisableScrolling } = props;
  const [isSubOpen, setIsSubOpen] = useState(false);
  // const [contactSuccess, setContactSuccess] = useState(false);
  const [contactLoading, setContactLoading] = useState(false);
  const [initialState, setInitialState] = useState({
    email: '',
  });
  const onModalClose = props => {
    setIsSubOpen(false);
    setInitialState({ email: '' });
  };
  const handleInputChange = e => {
    setInitialState({ email: e.target.value });
  };
  return (
    <>
      <FinalForm
        {...props}
        onSubmit={values => {
          setContactLoading(true);
          axios
            .post(`${apiBaseUrl()}/api/subscriptionMail`, {
              ...values,
            })
            .then(() => {
              setIsSubOpen(true);
              setContactLoading(false);
            })
            .catch(e => {
              setContactLoading(false);
            });
        }}
        initialValues={initialState}
        render={fieldRenderProps => {
          const { values, initialValues, handleSubmit } = fieldRenderProps;
          return (
            <Form onSubmit={handleSubmit} className={css.formContainer}>
              <div className={css.connectDiv}>
                <div>
                  <span>Stay Connected</span>
                  {/* <label>Email</label> */}
                  {/* <input type="email" /> */}
                  <FieldTextInput
                    name="email"
                    id="email"
                    type="email"
                    className={css.emailInput}
                    value={initialState.email}
                    onChange={handleInputChange}
                    //   validate={validators.composeValidators(emailRequired, emailValid)}
                  />
                  <p>
                    By joining, you agree to the Fleggs Terms of Service and to occasionally receive
                    emails from us. Please read our Privacy Policy to learn how we use your personal
                    data.
                  </p>
                </div>
                <div>
                  <button
                    // onClick={() => {
                    //   setIsSubOpen(true);
                    // }}
                    type="submit"
                    disabled={!values.email}
                  >
                    {contactLoading ? <IconSpinner /> : 'Subscribe'}
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      />
      <Modal
        containerClassName={css.carouselModalContainer}
        scrollLayerClassName={css.carouselModalScrollLayer}
        // className={css.modal}
        id="subscribeModal"
        isOpen={isSubOpen}
        onClose={() => onModalClose()}
        // showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        Thank you for subscribing to Fleggs! You'll now be kept up to date on all of our latest and
        most exciting news.
      </Modal>
    </>
  );
};

export default SubscribeSection;

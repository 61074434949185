import React, { useEffect, useState } from 'react';
import {
  FieldTextInput,
  Form,
  H3,
  IconSpinner,
  LayoutSideNavigation,
  Page,
} from '../../components';
import * as validators from '../../util/validators';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import css from './ContactUsPage.module.css';
import CustomFooter from '../../components/CustomFooter/CustomFooter';
import { Form as FinalForm } from 'react-final-form';
import axios from 'axios';
import { apiBaseUrl } from '../../util/api';

const ContactUsPage = props => {
  const { intl, isOpen } = props;
  const title = intl.formatMessage({ id: 'ContactUsPage.title' });
  const [contactSuccess, setContactSuccess] = useState(false);
  const [contactLoading, setContactLoading] = useState(false);
  const [initialState, setInitialState] = useState({
    name: '',
    message: '',
    email: '',
  });
  // email
  const emailRequired = validators.required(
    intl.formatMessage({
      id: 'ConfirmSignupForm.emailRequired',
    })
  );
  const emailValid = validators.emailFormatValid(
    intl.formatMessage({
      id: 'ConfirmSignupForm.emailInvalid',
    })
  );
  useEffect(() => {
    if (!isOpen) {
      setContactSuccess(false);
      setContactLoading(false);
      setInitialState({
        name: '',
        message: '',
        email: '',
      });
    }
  }, [isOpen]);

  return (
    <>
      <div className={css.content}>
        <H3 as="h1">
          {/* <FormattedMessage id="ContactDetailsPage.heading" /> */}
          Contact Us
        </H3>
        <div className={css.content}>
          <FinalForm
            {...props}
            onSubmit={values => {
              setContactLoading(true);
              axios
                .post(`${apiBaseUrl()}/api/sendContactUsMail`, {
                  ...values,
                })
                .then(() => {
                  setContactSuccess(true);
                  setContactLoading(false);
                })
                .catch(e => {
                  setContactLoading(false);
                });
            }}
            initialValues={initialState}
            render={fieldRenderProps => {
              const { values, initialValues, handleSubmit, invalid } = fieldRenderProps;
              const submitDisabled =
                invalid ||
                !values?.name?.trim() ||
                !values?.email?.trim() ||
                !values?.message?.trim();

              return contactSuccess ? (
                <div className={css.thnkdv}>
                  <h1>Thank you for getting in touch with us.</h1>
                  <p>We'll get back to you shortly.</p>
                  {/* <button
                        className={css.gohome}
                        onClick={() =>
                          history.push(
                            createResourceLocatorString('LandingPage', routeConfiguration(), {}, {})
                          )
                        }
                      >
                        Go to Homepage
                      </button> */}
                </div>
              ) : (
                <Form onSubmit={handleSubmit} className={css.formdiv}>
                  {/* <h1 className={css.heading}>CONTACT US</h1> */}
                  <div className={css.emaildv}>
                    <label>Name</label>
                    <FieldTextInput
                      name="name"
                      id="name"
                      type="text"
                      validate={validators.required('This field is required')}
                    />
                  </div>
                  <div className={css.emaildv}>
                    <label>Email</label>
                    <FieldTextInput
                      name="email"
                      id="email"
                      type="text"
                      validate={validators.composeValidators(emailRequired, emailValid)}
                    />
                  </div>
                  <div className={css.msgdv}>
                    <label>Message</label>
                    <FieldTextInput
                      name="message"
                      id="message"
                      type="textarea"
                      className={css.message}
                      validate={validators.required('This field is required')}
                      //   validate={messageRequired}
                    />
                  </div>
                  <button type="submit" disabled={submitDisabled}>
                    {contactLoading ? <IconSpinner /> : 'Submit'}
                  </button>
                </Form>
              );
            }}
          />
        </div>
      </div>
    </>
  );
};

export default compose(injectIntl)(ContactUsPage);

/**
 * Transaction process graph for bookings:
 *   - default-booking
 */

/**
 * Transitions
 *
 * These strings must sync with values defined in Marketplace API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */
export const paidEventTransitions = {
  REQUEST_PAYMENT_FOR_EVENT: 'transition/request-payment-for-event',
  CONFIRM_PAYMENT_FOR_EVENT: 'transition/confirm-payment-for-event',
  EXPIRE_PAYMENT_FOR_EVENT: 'transition/expire-payment-for-event',
  CANCEL_EVENT: 'transition/cancel-event',
  PROVIDER_CANCEL_EVENT: 'transition/provider-cancel-event',
  CUSTOMER_CANCEL_EVENT: 'transition/customer-cancel-event',
  EVENT_START: 'transition/event-start',
  COMPLETE_EVENT: 'transition/complete-event',
  OPERATOR_COMPLETE_EVENT: 'transition/operator-complete-event',
};
export const zeroEventTransitions = {
  REQUEST_ZERO_PAYMENT_EVENT: 'transition/request-zero-payment-event',
  ZERO_PAYMENT_EVENT_CREATED: 'transition/zero-payment-event-created',
  CANCEL_ZERO_EVENT: 'transition/cancel-zero-event',
  PROVIDER_CANCEL_ZERO_EVENT: 'transition/provider-cancel-zero-event',
  CUSTOMER_CANCEL_ZERO_EVENT: 'transition/customer-cancel-zero-event',
  EVENT_START_ZERO: 'transition/event-start-zero',
  COMPLETE_ZERO_EVENT: 'transition/complete-zero-event',
  OPERATOR_COMPLETE_ZERO_EVENT: 'transition/operator-complete-zero-event',
};
export const zeroDollarTransitions = {
  REQUEST_ZERO_PAYMENT: 'transition/request-zero-payment',
  CUSTOMER_ZERO_BOOKING_UPDDATE_BEFORE_ACCEPT:
    'transition/customer-request-zero-booking-change-before-accept',
  PROVIDER_ZERO_BOOKING_UPDDATE_BEFORE_ACCEPT:
    'transition/provider-request-zero-booking-change-before-accept',
  EXPIRE_ZERO: 'transition/expire-zero',
  OPERATOR_DECLINE_ZERO: 'transition/operator-decline-zero',
  CUSTOMER_DECLINE_ZERO: 'transition/customer-decline-zero',
  DECLINE_ZERO: 'transition/decline-zero',
  OPERATOR_ACCEPT_ZERO: 'transition/operator-accept-zero',
  ACCEPT_ZERO: 'transition/accept-zero',
  CUSTOMER_ZERO_BOOKING_UPDDATE_AFTER_ACCEPT:
    'transition/customer-request-zero-booking-change-after-accept',
  PROVIDER_ZERO_BOOKING_UPDDATE_AFTER_ACCEPT:
    'transition/provider-request-zero-booking-change-after-accept',
  CANCEL_ZERO: 'transition/cancel-zero',
  PROVIDER_CANCEL_ZERO: 'transition/provider-cancel-zero',
  CUSTOMER_CANCEL_ZERO: 'transition/customer-cancel-zero',
  SESSION_START_ZERO: 'transition/session-start-zero',
  COMPLETE_ZERO: 'transition/complete-zero',
  OPERATOR_COMPLETE_ZERO: 'transition/operator-complete-zero',
};

export const transitions = {
  // When a customer makes a booking to a listing, a transaction is
  // created with the initial request-payment transition.
  // At this transition a PaymentIntent is created by Marketplace API.
  // After this transition, the actual payment must be made on client-side directly to Stripe.
  REQUEST_PAYMENT: 'transition/request-payment',

  // A customer can also initiate a transaction with an inquiry, and
  // then transition that with a request.
  INQUIRE: 'transition/inquire',
  REQUEST_PAYMENT_AFTER_INQUIRY: 'transition/request-payment-after-inquiry',

  // Stripe SDK might need to ask 3D security from customer, in a separate front-end step.
  // Therefore we need to make another transition to Marketplace API,
  // to tell that the payment is confirmed.
  CONFIRM_PAYMENT: 'transition/confirm-payment',
  CUSTOMER_BOOKING_UPDDATE_BEFORE_ACCEPT:
    'transition/customer-request-booking-change-before-accept',
  PROVIDER_BOOKING_UPDDATE_BEFORE_ACCEPT:
    'transition/provider-request-booking-change-before-accept',

  // If the payment is not confirmed in the time limit set in transaction process (by default 15min)
  // the transaction will expire automatically.
  EXPIRE_PAYMENT: 'transition/expire-payment',

  // When the provider accepts or declines a transaction from the
  // SalePage, it is transitioned with the accept or decline transition.
  ACCEPT: 'transition/accept',
  REAMINDER_BEFORE_24_HOUR: 'transition/remainder-before-24-hour',
  REAMINDER_BEFORE_1_HOUR: 'transition/remainder-before-1-hour',
  SESSION_START: 'transition/session-start',
  CUSTOMER_BOOKING_UPDDATE_AFTER_ACCEPT: 'transition/customer-request-booking-change-after-accept',
  PROVIDER_BOOKING_UPDDATE_AFTER_ACCEPT: 'transition/provider-request-booking-change-after-accept',
  DECLINE: 'transition/decline',
  CUSTOMER_DECLINE: 'transition/customer-decline',

  // The operator can accept or decline the offer on behalf of the provider
  OPERATOR_ACCEPT: 'transition/operator-accept',
  OPERATOR_DECLINE: 'transition/operator-decline',

  // The backend automatically expire the transaction.
  EXPIRE: 'transition/expire',

  // Admin can also cancel the transition.
  CANCEL: 'transition/cancel',
  CUSTOMER_CANCEL: 'transition/customer-cancel',
  PROVIDER_CANCEL: 'transition/provider-cancel',

  CUSTOMER_CANCEL_BEFORE_1_HOUR: 'transition/customer-cancel-before-1-hour',
  PROVIDER_CANCEL_BEFORE_1_HOUR: 'transition/provider-cancel-before-1-hour',
  CUSTOMER_CANCEL_BEFORE_24_HOUR: 'transition/customer-cancel-before-24-hour',
  PROVIDER_CANCEL_BEFORE_24_HOUR: 'transition/provider-cancel-before-24-hour',

  // The backend will mark the transaction completed.
  COMPLETE: 'transition/complete',
  OPERATOR_COMPLETE: 'transition/operator-complete',

  // Reviews are given through transaction transitions. Review 1 can be
  // by provider or customer, and review 2 will be the other party of
  // the transaction.
  REVIEW_1_BY_PROVIDER: 'transition/review-1-by-provider',
  REVIEW_2_BY_PROVIDER: 'transition/review-2-by-provider',
  REVIEW_1_BY_CUSTOMER: 'transition/review-1-by-customer',
  REVIEW_2_BY_CUSTOMER: 'transition/review-2-by-customer',
  EXPIRE_CUSTOMER_REVIEW_PERIOD: 'transition/expire-customer-review-period',
  EXPIRE_PROVIDER_REVIEW_PERIOD: 'transition/expire-provider-review-period',
  EXPIRE_REVIEW_PERIOD: 'transition/expire-review-period',
  ...zeroDollarTransitions,
  ...paidEventTransitions,
  ...zeroEventTransitions,
};

export const customerReviewTransitons = {
  // The backend will mark the transaction completed.
  COMPLETE: 'transition/complete',
  OPERATOR_COMPLETE: 'transition/operator-complete',
  COMPLETE_ZERO: 'transition/complete-zero',
  OPERATOR_COMPLETE_ZERO: 'transition/operator-complete-zero',
  COMPLETE_EVENT: 'transition/complete-event',
  OPERATOR_COMPLETE_EVENT: 'transition/operator-complete-event',
  COMPLETE_ZERO_EVENT: 'transition/complete-zero-event',
  OPERATOR_COMPLETE_ZERO_EVENT: 'transition/operator-complete-zero-event',
  // Reviews are given through transaction transitions. Review 1 can be
  // by provider or customer, and review 2 will be the other party of
  // the transaction.
  REVIEW_1_BY_PROVIDER: 'transition/review-1-by-provider',
};

export const cancelableTransitions = {
  // When the provider accepts or declines a transaction from the
  // SalePage, it is transitioned with the accept or decline transition.
  ACCEPT: 'transition/accept',

  CUSTOMER_BOOKING_UPDDATE_AFTER_ACCEPT: 'transition/customer-request-booking-change-after-accept',
  PROVIDER_BOOKING_UPDDATE_AFTER_ACCEPT: 'transition/provider-request-booking-change-after-accept',
  // The operator can accept or decline the offer on behalf of the provider
  OPERATOR_ACCEPT: 'transition/operator-accept',
  CUSTOMER_ZERO_BOOKING_UPDDATE_AFTER_ACCEPT:
    'transition/customer-request-zero-booking-change-after-accept',
  PROVIDER_ZERO_BOOKING_UPDDATE_AFTER_ACCEPT:
    'transition/provider-request-zero-booking-change-after-accept',
  OPERATOR_ACCEPT_ZERO: 'transition/operator-accept-zero',
  ACCEPT_ZERO: 'transition/accept-zero',
  CONFIRM_PAYMENT_FOR_EVENT: 'transition/confirm-payment-for-event',
  ZERO_PAYMENT_EVENT_CREATED: 'transition/zero-payment-event-created',
};

export const toReviewTransitions = {
  // The backend will mark the transaction completed.
  COMPLETE: 'transition/complete',
  OPERATOR_COMPLETE: 'transition/operator-complete',
  COMPLETE_ZERO: 'transition/complete-zero',
  OPERATOR_COMPLETE_ZERO: 'transition/operator-complete-zero',
  COMPLETE_EVENT: 'transition/complete-event',
  OPERATOR_COMPLETE_EVENT: 'transition/operator-complete-event',
  COMPLETE_ZERO_EVENT: 'transition/complete-zero-event',
  OPERATOR_COMPLETE_ZERO_EVENT: 'transition/operator-complete-zero-event',

  REVIEW_1_BY_CUSTOMER: 'transition/review-1-by-customer',
};

export const upcomingTransitions = {
  // When a customer makes a booking to a listing, a transaction is
  // created with the initial request-payment transition.
  // At this transition a PaymentIntent is created by Marketplace API.
  // After this transition, the actual payment must be made on client-side directly to Stripe.
  REQUEST_PAYMENT: 'transition/request-payment',
  REQUEST_ZERO_PAYMENT: 'transition/request-zero-payment',

  // A customer can also initiate a transaction with an inquiry, and
  // then transition that with a request.
  INQUIRE: 'transition/inquire',
  REQUEST_PAYMENT_AFTER_INQUIRY: 'transition/request-payment-after-inquiry',
  // Stripe SDK might need to ask 3D security from customer, in a separate front-end step.
  // Therefore we need to make another transition to Marketplace API,
  // to tell that the payment is confirmed.
  CONFIRM_PAYMENT: 'transition/confirm-payment',
  CUSTOMER_BOOKING_UPDDATE_BEFORE_ACCEPT:
    'transition/customer-request-booking-change-before-accept',
  PROVIDER_BOOKING_UPDDATE_BEFORE_ACCEPT:
    'transition/provider-request-booking-change-before-accept',

  CUSTOMER_ZERO_BOOKING_UPDDATE_BEFORE_ACCEPT:
    'transition/customer-request-zero-booking-change-before-accept',
  PROVIDER_ZERO_BOOKING_UPDDATE_BEFORE_ACCEPT:
    'transition/provider-request-zero-booking-change-before-accept',

  // When the provider accepts or declines a transaction from the
  // SalePage, it is transitioned with the accept or decline transition.
  // ACCEPT: 'transition/accept',
  REAMINDER_BEFORE_24_HOUR: 'transition/remainder-before-24-hour',
  REAMINDER_BEFORE_1_HOUR: 'transition/remainder-before-1-hour',
  SESSION_START: 'transition/session-start',
  CUSTOMER_BOOKING_UPDDATE_AFTER_ACCEPT: 'transition/customer-request-booking-change-after-accept',
  PROVIDER_BOOKING_UPDDATE_AFTER_ACCEPT: 'transition/provider-request-booking-change-after-accept',

  // ACCEPT_ZERO: 'transition/accept-zero',
  CUSTOMER_ZERO_BOOKING_UPDDATE_AFTER_ACCEPT:
    'transition/customer-request-zero-booking-change-after-accept',
  PROVIDER_ZERO_BOOKING_UPDDATE_AFTER_ACCEPT:
    'transition/provider-request-zero-booking-change-after-accept',
  SESSION_START_ZERO: 'transition/session-start-zero',
  REQUEST_PAYMENT_FOR_EVENT: 'transition/request-payment-for-event',
  CONFIRM_PAYMENT_FOR_EVENT: 'transition/confirm-payment-for-event',
  ZERO_PAYMENT_EVENT_CREATED: 'transition/transition/zero-payment-event-created',
  EVENT_START: 'transition/event-start',
  EVENT_START_ZERO: 'transition/event-start-zero',
};

export const passTransitions = {
  // Stripe SDK might need to ask 3D security from customer, in a separate front-end step.
  // Therefore we need to make another transition to Marketplace API,
  // to tell that the payment is confirmed.

  // If the payment is not confirmed in the time limit set in transaction process (by default 15min)
  // the transaction will expire automatically.
  EXPIRE_PAYMENT: 'transition/expire-payment',

  DECLINE: 'transition/decline',
  CUSTOMER_DECLINE: 'transition/customer-decline',

  DECLINE_ZERO: 'transition/decline-zero',
  CUSTOMER_DECLINE_ZERO: 'transition/customer-decline-zero',

  // The operator can accept or decline the offer on behalf of the provider
  OPERATOR_ACCEPT: 'transition/operator-accept',
  OPERATOR_DECLINE: 'transition/operator-decline',

  OPERATOR_ACCEPT_ZERO: 'transition/operator-accept-zero',
  OPERATOR_DECLINE_ZERO: 'transition/operator-decline-zero',

  // The backend automatically expire the transaction.
  EXPIRE: 'transition/expire',
  EXPIRE_ZERO: 'transition/expire-zero',

  // Admin can also cancel the transition.
  CANCEL: 'transition/cancel',
  CUSTOMER_CANCEL: 'transition/customer-cancel',
  PROVIDER_CANCEL: 'transition/provider-cancel',

  CANCEL_ZERO: 'transition/cancel-zero',
  PROVIDER_CANCEL_ZERO: 'transition/provider-cancel-zero',
  CUSTOMER_CANCEL_ZERO: 'transition/customer-cancel-zero',

  CANCEL_EVENT: 'transition/cancel-event',
  PROVIDER_CANCEL_EVENT: 'transition/provider-cancel-event',
  CUSTOMER_CANCEL_EVENT: 'transition/customer-cancel-event',

  CANCEL_ZERO_EVENT: 'transition/cancel-zero-event',
  PROVIDER_CANCEL_ZERO_EVENT: 'transition/provider-cancel-zero-event',
  CUSTOMER_CANCEL_ZERO_EVENT: 'transition/customer-cancel-zero-event',

  EXPIRE_PAYMENT_FOR_EVENT: 'transition/expire-payment-for-event',

  // Reviews are given through transaction transitions. Review 1 can be
  // by provider or customer, and review 2 will be the other party of
  // the transaction.
  REVIEW_1_BY_PROVIDER: 'transition/review-1-by-provider',
  REVIEW_2_BY_PROVIDER: 'transition/review-2-by-provider',
  REVIEW_2_BY_CUSTOMER: 'transition/review-2-by-customer',
  EXPIRE_CUSTOMER_REVIEW_PERIOD: 'transition/expire-customer-review-period',
  EXPIRE_PROVIDER_REVIEW_PERIOD: 'transition/expire-provider-review-period',
  EXPIRE_REVIEW_PERIOD: 'transition/expire-review-period',
};

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
export const paidEventsStates = {
  PENDING_EVENT_PAYMENT: 'pending-event-payment',
  PREAUTHORIZED_EVENT: 'preauthorized-event',
  EVENT_STARTED: 'event-started',
};
export const zeroEventsStates = {
  PREAUTHORIZED_ZERO_EVENT: 'preauthorized-zero-event',
  ZERO_EVENT_PENDING_CREATION: 'zero-event-pending-creation',
  EVENT_STARTED_ZERO: 'event-started-zero',
};
export const zeroStates = {
  PREAUTHORIZED_ZERO: 'preauthorized-zero',
  ACCEPTED_ZERO: 'accepted-zero',
  SESSION_START_ZERO: 'session-started-zero',
};
export const states = {
  INITIAL: 'initial',
  INQUIRY: 'inquiry',
  PENDING_PAYMENT: 'pending-payment',
  PAYMENT_EXPIRED: 'payment-expired',
  PREAUTHORIZED: 'preauthorized',
  DECLINED: 'declined',
  ACCEPTED: 'accepted',
  EXPIRED: 'expired',
  CANCELED: 'canceled',
  DELIVERED: 'delivered',
  REVIEWED: 'reviewed',
  REVIEWED_BY_CUSTOMER: 'reviewed-by-customer',
  REVIEWED_BY_PROVIDER: 'reviewed-by-provider',
  REAMINDER_BEFORE_24_HOUR: 'remainder-before-24-hour',
  REAMINDER_BEFORE_1_HOUR: 'remainder-before-1-hour',
  SESSION_START: 'session-started',
  ...zeroStates,
  ...paidEventsStates,
  ...zeroEventsStates,
};

/**
 * Description of transaction process graph
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
export const graph = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'default-booking/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: states.INITIAL,

  // States
  states: {
    [states.INITIAL]: {
      on: {
        [transitions.INQUIRE]: states.INQUIRY,
        [transitions.REQUEST_PAYMENT]: states.PENDING_PAYMENT,
        [transitions.REQUEST_ZERO_PAYMENT]: states.PREAUTHORIZED_ZERO,
        [transitions.REQUEST_PAYMENT_FOR_EVENT]: states.PENDING_EVENT_PAYMENT,
        [transitions.REQUEST_ZERO_PAYMENT_EVENT]: states.ZERO_EVENT_PENDING_CREATION,
      },
    },
    [states.ZERO_EVENT_PENDING_CREATION]: {
      on: {
        [transitions.ZERO_PAYMENT_EVENT_CREATED]: states.PREAUTHORIZED_ZERO_EVENT,
      },
    },
    [states.PENDING_EVENT_PAYMENT]: {
      on: {
        [transitions.CONFIRM_PAYMENT_FOR_EVENT]: states.PREAUTHORIZED_EVENT,
        [transitions.EXPIRE_PAYMENT_FOR_EVENT]: states.EXPIRED,
      },
    },
    [states.PREAUTHORIZED_EVENT]: {
      on: {
        [transitions.CANCEL_EVENT]: states.CANCELED,
        [transitions.PROVIDER_CANCEL_EVENT]: states.CANCELED,
        [transitions.CUSTOMER_CANCEL_EVENT]: states.CANCELED,
        [transitions.EVENT_START]: states.EVENT_STARTED,
      },
    },
    [states.EVENT_STARTED]: {
      on: {
        [transitions.COMPLETE_EVENT]: states.DELIVERED,
        [transitions.OPERATOR_COMPLETE_EVENT]: states.DELIVERED,
      },
    },
    [states.PREAUTHORIZED_ZERO_EVENT]: {
      on: {
        [transitions.CANCEL_ZERO_EVENT]: states.CANCELED,
        [transitions.PROVIDER_CANCEL_ZERO_EVENT]: states.CANCELED,
        [transitions.CUSTOMER_CANCEL_ZERO_EVENT]: states.CANCELED,
        [transitions.EVENT_START_ZERO]: states.EVENT_STARTED_ZERO,
      },
    },
    [states.EVENT_STARTED_ZERO]: {
      on: {
        [transitions.COMPLETE_ZERO_EVENT]: states.DELIVERED,
        [transitions.OPERATOR_COMPLETE_ZERO_EVENT]: states.DELIVERED,
      },
    },
    [states.PREAUTHORIZED_ZERO]: {
      on: {
        [transitions.DECLINE_ZERO]: states.DECLINED,
        [transitions.CUSTOMER_DECLINE_ZERO]: states.DECLINED,
        [transitions.OPERATOR_DECLINE_ZERO]: states.DECLINED,
        [transitions.EXPIRE_ZERO]: states.EXPIRED,
        [transitions.ACCEPT_ZERO]: states.ACCEPTED_ZERO,
        [transitions.OPERATOR_ACCEPT_ZERO]: states.ACCEPTED_ZERO,
        [transitions.CUSTOMER_ZERO_BOOKING_UPDDATE_BEFORE_ACCEPT]: states.PREAUTHORIZED_ZERO,
        [transitions.PROVIDER_ZERO_BOOKING_UPDDATE_BEFORE_ACCEPT]: states.PREAUTHORIZED_ZERO,
      },
    },
    [states.ACCEPTED_ZERO]: {
      on: {
        [transitions.CANCEL_ZERO]: states.CANCELED,
        [transitions.CUSTOMER_CANCEL_ZERO]: states.CANCELED,
        [transitions.PROVIDER_CANCEL_ZERO]: states.CANCELED,
        [transitions.CUSTOMER_ZERO_BOOKING_UPDDATE_AFTER_ACCEPT]: states.ACCEPTED_ZERO,
        [transitions.PROVIDER_ZERO_BOOKING_UPDDATE_AFTER_ACCEPT]: states.ACCEPTED_ZERO,
      },
    },
    [states.SESSION_START_ZERO]: {
      on: {
        [transitions.COMPLETE_ZERO]: states.DELIVERED,
        [transitions.OPERATOR_COMPLETE_ZERO]: states.DELIVERED,
      },
    },
    [states.INQUIRY]: {
      on: {
        [transitions.REQUEST_PAYMENT_AFTER_INQUIRY]: states.PENDING_PAYMENT,
      },
    },

    [states.PENDING_PAYMENT]: {
      on: {
        [transitions.EXPIRE_PAYMENT]: states.PAYMENT_EXPIRED,
        [transitions.CONFIRM_PAYMENT]: states.PREAUTHORIZED,
      },
    },

    [states.PAYMENT_EXPIRED]: {},
    [states.PREAUTHORIZED]: {
      on: {
        [transitions.DECLINE]: states.DECLINED,
        [transitions.CUSTOMER_DECLINE]: states.DECLINED,
        [transitions.OPERATOR_DECLINE]: states.DECLINED,
        [transitions.EXPIRE]: states.EXPIRED,
        [transitions.ACCEPT]: states.ACCEPTED,
        [transitions.OPERATOR_ACCEPT]: states.ACCEPTED,
        [transitions.CUSTOMER_BOOKING_UPDDATE_BEFORE_ACCEPT]: states.PREAUTHORIZED,
        [transitions.PROVIDER_BOOKING_UPDDATE_BEFORE_ACCEPT]: states.PREAUTHORIZED,
      },
    },

    [states.DECLINED]: {},
    [states.EXPIRED]: {},
    [states.ACCEPTED]: {
      on: {
        [transitions.CANCEL]: states.CANCELED,
        [transitions.CUSTOMER_CANCEL]: states.CANCELED,
        [transitions.PROVIDER_CANCEL]: states.CANCELED,
        [transitions.CUSTOMER_BOOKING_UPDDATE_AFTER_ACCEPT]: states.ACCEPTED,
        [transitions.PROVIDER_BOOKING_UPDDATE_AFTER_ACCEPT]: states.ACCEPTED,
        [transitions.REAMINDER_BEFORE_24_HOUR]: states.REAMINDER_BEFORE_24_HOUR,
      },
    },
    [states.REAMINDER_BEFORE_24_HOUR]: {
      on: {
        [transitions.REAMINDER_BEFORE_1_HOUR]: states.REAMINDER_BEFORE_1_HOUR,
      },
    },
    [states.REAMINDER_BEFORE_1_HOUR]: {
      on: {
        [transitions.SESSION_START]: states.SESSION_START,
      },
    },
    [states.SESSION_START]: {
      on: {
        [transitions.COMPLETE]: states.DELIVERED,
        [transitions.OPERATOR_COMPLETE]: states.DELIVERED,
      },
    },
    [states.CANCELED]: {},
    [states.DELIVERED]: {
      on: {
        [transitions.EXPIRE_REVIEW_PERIOD]: states.REVIEWED,
        [transitions.REVIEW_1_BY_CUSTOMER]: states.REVIEWED_BY_CUSTOMER,
        [transitions.REVIEW_1_BY_PROVIDER]: states.REVIEWED_BY_PROVIDER,
      },
    },

    [states.REVIEWED_BY_CUSTOMER]: {
      on: {
        [transitions.REVIEW_2_BY_PROVIDER]: states.REVIEWED,
        [transitions.EXPIRE_PROVIDER_REVIEW_PERIOD]: states.REVIEWED,
      },
    },
    [states.REVIEWED_BY_PROVIDER]: {
      on: {
        [transitions.REVIEW_2_BY_CUSTOMER]: states.REVIEWED,
        [transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD]: states.REVIEWED,
      },
    },
    [states.REVIEWED]: { type: 'final' },
  },
};

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    transitions.ACCEPT,
    transitions.OPERATOR_ACCEPT,
    transitions.ACCEPT_ZERO,
    transitions.OPERATOR_ACCEPT_ZERO,
    transitions.CANCEL,
    transitions.COMPLETE,
    transitions.CANCEL_ZERO,
    transitions.COMPLETE_ZERO,
    transitions.OPERATOR_COMPLETE,
    transitions.OPERATOR_COMPLETE_ZERO,
    transitions.CONFIRM_PAYMENT,
    transitions.DECLINE,
    transitions.OPERATOR_DECLINE,
    transitions.EXPIRE,
    transitions.DECLINE_ZERO,
    transitions.OPERATOR_DECLINE_ZERO,
    transitions.EXPIRE_ZERO,
    transitions.COMPLETE_EVENT,
    transitions.OPERATOR_COMPLETE_EVENT,
    transitions.COMPLETE_ZERO_EVENT,
    transitions.OPERATOR_COMPLETE_ZERO_EVENT,
    transitions.CANCEL_EVENT,
    transitions.PROVIDER_CANCEL_EVENT,
    transitions.CANCEL_ZERO_EVENT,
    transitions.PROVIDER_CANCEL_ZERO_EVENT,
    transitions.REVIEW_1_BY_CUSTOMER,
    transitions.REVIEW_1_BY_PROVIDER,
    transitions.REVIEW_2_BY_CUSTOMER,
    transitions.REVIEW_2_BY_PROVIDER,
  ].includes(transition);
};

// Processes might be different on how reviews are handled.
// Default processes use two-sided diamond shape, where either party can make the review first
export const isCustomerReview = transition => {
  return [transitions.REVIEW_1_BY_CUSTOMER, transitions.REVIEW_2_BY_CUSTOMER].includes(transition);
};

// Processes might be different on how reviews are handled.
// Default processes use two-sided diamond shape, where either party can make the review first
export const isProviderReview = transition => {
  return [transitions.REVIEW_1_BY_PROVIDER, transitions.REVIEW_2_BY_PROVIDER].includes(transition);
};

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
  return [
    transitions.REQUEST_PAYMENT,
    transitions.REQUEST_PAYMENT_AFTER_INQUIRY,
    transitions.REQUEST_ZERO_PAYMENT,
    transitions.REQUEST_PAYMENT_FOR_EVENT,
    transitions.REQUEST_ZERO_PAYMENT_EVENT,
  ].includes(transition);
};

// Check when transaction is completed (booking over)
export const isCompleted = transition => {
  const txCompletedTransitions = [
    transitions.COMPLETE,
    transitions.OPERATOR_COMPLETE,
    transitions.COMPLETE_ZERO,
    transitions.OPERATOR_COMPLETE_ZERO,
    transitions.COMPLETE_EVENT,
    transitions.OPERATOR_COMPLETE_EVENT,
    transitions.COMPLETE_ZERO_EVENT,
    transitions.OPERATOR_COMPLETE_ZERO_EVENT,
    transitions.REVIEW_1_BY_CUSTOMER,
    transitions.REVIEW_1_BY_PROVIDER,
    transitions.REVIEW_2_BY_CUSTOMER,
    transitions.REVIEW_2_BY_PROVIDER,
    transitions.EXPIRE_REVIEW_PERIOD,
    transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD,
    transitions.EXPIRE_PROVIDER_REVIEW_PERIOD,
  ];
  return txCompletedTransitions.includes(transition);
};

// Check when transaction is refunded (booking did not happen)
// In these transitions action/stripe-refund-payment is called
export const isRefunded = transition => {
  const txRefundedTransitions = [
    transitions.EXPIRE_PAYMENT,
    transitions.EXPIRE_PAYMENT_FOR_EVENT,
    transitions.EXPIRE,
    transitions.CANCEL,
    transitions.DECLINE,
    transitions.CANCEL_EVENT,
    transitions.PROVIDER_CANCEL_EVENT,
    transitions.CUSTOMER_CANCEL_EVENT,
  ];
  return txRefundedTransitions.includes(transition);
};

export const isUpcomingTransaction = transition =>
  Object.values(upcomingTransitions).includes(transition);
export const isToReviewTransaction = transition =>
  Object.values(toReviewTransitions).includes(transition);
export const isPastTransactions = transition => Object.values(passTransitions).includes(transition);

export const isConfirmedTransaction = transition =>
         [
           transitions.ACCEPT,
           transitions.ACCEPT_ZERO,
           transitions.REAMINDER_BEFORE_1_HOUR,
           transitions.REAMINDER_BEFORE_24_HOUR,
           transitions.CONFIRM_PAYMENT_FOR_EVENT,
           transitions.ZERO_PAYMENT_EVENT_CREATED,
           transitions.CONFIRM_PAYMENT,
         ].includes(transition);
export const isSesstionStartTransaction = transition =>
  [
    transitions.SESSION_START,
    transitions.SESSION_START_ZERO,
    transitions.EVENT_START,
    transitions.EVENT_START_ZERO,
  ].includes(transition);
export const isCancelableTransaction = transition =>
  Object.values(cancelableTransitions).includes(transition);
export const isCustomerReviewTransaction = transition =>
  Object.values(customerReviewTransitons).includes(transition);

export const statesNeedingProviderAttention = [states.PREAUTHORIZED, states.PREAUTHORIZED_ZERO];

import React from 'react';
import classNames from 'classnames';
import css from './BookingTC.module.css';
import { string } from 'prop-types';
import { FormattedMessage } from 'react-intl';

function BookingTC(props) {
  const { className, titleClassName, bodyClassName } = props;
  const classes = classNames(css.main, className);
  return (
    <div className={classNames(classes,css.bookingTCPage)}>
      <span className={titleClassName}>
        <FormattedMessage id="bookingTc.title" />
      </span>
      <p className={bodyClassName}>
        <FormattedMessage id="bookingTc.desc" />
      </p>
    </div>
  );
}

BookingTC.propsTypes = {
  className: string,
  titleClassName: string,
  bodyClassName: string,
};

export default BookingTC;

import React from 'react';
import { ReactComponent as OnlineLogo } from '../../assets/onlineLogo.svg';
import { FormattedMessage, injectIntl } from 'react-intl';
import css from './OnlineWorkoutType.module.css';
import classNames from 'classnames';

function OnlineWorkoutType(props) {
  const { intl, className } = props;
  const classes = classNames(css.property, className);
  return (
    <div className={classes}>
      <OnlineLogo className={css.mobileIcon} />
      <FormattedMessage id="OnlineWorkout.title" />
      <OnlineLogo className={css.icon} />
    </div>
  );
}

export default injectIntl(OnlineWorkoutType);

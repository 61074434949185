import React, { useEffect } from 'react';
import { useSelectContext } from './selectContext';
import { bool, node, number, oneOfType, string } from 'prop-types';
import css from './FieldCustomSelect.module.css';
import classNames from 'classnames';

function SelectOption(props) {
  const { value, children, className, hidden, disabled } = props;
  const context = useSelectContext();
  const { onChange, addOption, selectedValue } = context;

  const handleClick = e => {
    e.preventDefault();
    if (disabled || hidden) return;
    e.currentTarget.value = value;
    onChange(e);
  };

  const classes = classNames(css.option, className, {
    [css.selectedOption]: selectedValue == value,
    [css.hiddenOption]: hidden,
    [css.disabledOption]: disabled,
  });

  useEffect(() => {
    addOption(value, children);
  }, []);

  return (
    <button onClick={handleClick} type="button" className={classes} disabled={disabled}>
      {children}
    </button>
  );
}
SelectOption.defaultProps = {
  classNames: null,
  hidden: false,
  disabled: false,
};

SelectOption.propTypes = {
  value: oneOfType([string, number]),
  children: node,
  className: string,
  hidden: bool,
  disabled: bool,
};

export default SelectOption;

export const listingServiceType = {
  offline: 'offline',
  online: 'online',
};

export const providerBookingTab = {
  toReview: 'toReview',
  past: 'past',
  upcoming: 'upcoming',
  all: 'all',
};

export const bookingState = {
  ...providerBookingTab,
  confirmed: 'confirmed',
  cancelable: 'cancelable',
  rescheduled: 'rescheduled',
  customerReview: 'customerReview',
  sessionStarted: 'sessionStarted',
};

export const paymentType = {
  full: 'full',
  split: 'split',
};
